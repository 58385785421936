.container{
    display: block;
    height: 100vh;
    width: 255px;
    background-color: #363740;
    z-index: 1000;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
}

.header{
    display: flex;
    padding: 30px 0;
    justify-content: center;
    align-items: center;

    img{
        margin-right: 13px;
        width: 46px;
        height: 46px;
    }
}
.headerTitles{
    h3,h4{
        // font-family: $primeryFont;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        color: #A4A6B3;
        opacity: 0.7;
    }
    h4{
        font-weight: 500;
        font-size: 12px;
    }
   
}

.nav{
    display: flex;
    flex-direction: column;
    // height: 100%;
    flex: 1;
}

.navLinks{
    display: flex;
    flex-direction: column;
    // height: 100%;
    flex: 1;
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
        width: 100%;
        

        .link{
            padding: 20px 0;
            padding-left: 30px;
            transition: 0.3s;
            border-left: 3px solid transparent;
            outline: none;
            &:hover{
                background: rgba(159, 162, 180, 0.1);
                border-left: 3px solid #DDE2FF;
                
            }
            text-decoration: none;
            display: flex;
            align-items: center;
            svg{
                margin-right: 20px;
            }
            span{
                // font-family: $primeryFont;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: #A4A6B3;
            }
            &.selected{
                background: rgba(159, 162, 180, 0.1);
                border-left: 3px solid #DDE2FF;
                span{
                    color: #DDE2FF;
                }
                path{
                    fill: #DDE2FF
                }
            }
        }
    }
}