.container{
    padding: 20px;
}

h1{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #252733;

}