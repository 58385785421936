body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin: 0;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
}
.error{
  color: red;
}