// @import '../../globalStyles/variables.scss';
.container{
    background-color: #363740;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block{
    background-color: #fff;
    width: 100%;
    max-width: 455px;
    // min-height: 455px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 15px;
    border-radius: 8px;
}
.error{
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    color: #FF5252;
    margin-top: 10px;

}
.header{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    &_logo{
        width: 46px;
        height: 46px;
        margin-right: 13px;
    }
    &_title{
        h3{
            font-weight: 700;
            font-size: 19px;
            color: #363740
        }
        h4{
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #363740

        }
    }
    
}

.form{
    h2{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        color: #A3A3A3;
        margin-bottom: 12px;
    }
    .forgot{
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        color: #A3A3A3;
    }
    button{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        background: linear-gradient(89.53deg, #5BD26A 3.38%, #65DF76 99.58%);
        display: block;
        width: 100%;
        border: 0;
        outline: none;
        cursor: pointer;
        border-radius: 25px;
        margin: 0 auto;
        margin-top: 10px;
        max-width: 150px;
        padding: 20px 0;
        transition: 0.2s;
        &:hover{
            // background-color: darken(#5BD26A, 10);
            background: linear-gradient(89.53deg, darken(#5BD26A, 10) 3.38%, darken(#65DF76,10) 99.58%);

        }
        &.disabled{
            background: linear-gradient(89.53deg, rgba(91, 210, 106, 0.6) 3.38%, rgba(101, 223, 118, 0.6) 99.58%);
            cursor: initial;
            transition: 0.2s;
        }
        

           
    }
    
   
}

 

.inputBlock{
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    
}

.input{
    width: 100%;
    margin-bottom: 10px !important;
    
    
}

.inputText{
    font-weight: 300 !important;
    font-size: 14px !important;
    border-color: red;

}

.partnersLink{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #FFAB5A;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 35px;

}

.message{
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    color: #A3A3A3;
    margin-bottom: 10px;
}