.selectGroup{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // border-bottom: 1px solid gray;
    margin: 10px;
    // margin-bottom: 50px;
    display: inline-block;
    height: 150px;
    p{
        margin: 0;
    }
}
.inputGroup{
    margin-bottom: 20px;
    
}
.input{
    width: 100%;
 
    
}

.loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.products{
    .select{
        width: 500px;
        margin-bottom: 10px;
    }
   
}

.w_50{
    width: 50%;
}

.submit{
    margin-right: 20px !important;
}

.logo{
    display: flex;
    justify-content: center;
}

.title{
    text-align: center;
}

.recipientsFile{
    margin-right: 10px;
}