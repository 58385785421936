// .container{}

.select{
    width: 300px;

}
.selectBlock{
    margin-top: 30px;
    margin-left: 30px;
    margin-bottom: 30px;
}
.templates{
    width: 70%;
    margin: 0 auto;
}

.notSelected{
    font-size: 22px;
    color: darkgray;
    font-weight: 600;
    // text-transform: uppercase;t
    text-align: center;
    margin-top: 200px;
}